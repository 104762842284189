<template>
  <el-container :key="reRender" class="wrapper">
    <el-container class="is-vertical">
      <vHeader></vHeader>
      <el-main id="elMain">
        <transition>
          <router-view></router-view>
        </transition>
        <vFooterLogin />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import vHeader from '@/components/Header';
import vFooterLogin from '@/components/FooterLogin';
import { apiSetlanguage } from '@/resource';

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0
    };
  },
  components: { vHeader, vFooterLogin },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) this.setUserLanguagePreference(val);
        this.reRender++;
      }
    },
    reRender() {
      if (this.$route.meta.restrictReload) this.$router.push('/home');
    }
  },
  methods: {
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
      this.$store.dispatch('regulator/actionGetInformation');
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}

@import '../../assets/GTWalsheimPro/stylesheet.css';
</style>
